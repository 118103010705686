/* CUSTOM SCROLLBAR */
::-webkit-scrollbar {
  width: 0px;
  height: 5px;
  border-radius: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background:  transparent;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.gamebg{
  
  animation: rotate-img 8s infinite;
}
@keyframes rotate-img {
  0%{
    transform: rotate(0deg);
  }
  100%{
    transform: rotate(360deg);
  }
}

.backgroundImg{
  background-image: url("./Assets/blurrybg.png");
  background-size: cover;
  background-repeat: no-repeat;
}
.vrdots{
  animation: vrdots 10s linear infinite;
}
@keyframes vrdots {
  0%{
    transform: translateY(-100%);
  }
  100%{
    transform: translateY(0);
  }
}

.hrdots{
  animation: hrdots 10s linear infinite;
}
@keyframes hrdots {
  0%{
    transform: translateX(0);
  }
  100%{
    transform: translateX(-100%);
  }
}
.ant-message-notice-content{
  background: #123405 !important;
  border-radius: 100px !important;
}
.profileModal  .ant-modal-content{
  @apply bg-primary rounded-md
  /* background-color: #181C27 !important;
  border-radius: 1px !important; */
}
.radient{
  background: linear-gradient(0deg, rgba(119,1,0,1) 66%, rgba(237,59,57,0.3029586834733894) 100%);
}
.rotate{
  animation: sspin 10s linear infinite ;
  transform: scale(2.2);
}
@keyframes sspin {
  100%{
    transform:scale(2.2) rotate(360deg)  ;
  }
}

